@font-face {
	font-family: 'Dubai';
	src: url('../fonts/dubai-font/DubaiW23-Bold.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/dubai-font/DubaiW23-Bold.woff') format('woff'),
		 url('../fonts/dubai-font/DubaiW23-Bold.ttf') format('truetype'),
		 url('../fonts/dubai-font/DubaiW23-Bold.woff2#Dubai-Bold') format('woff2');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: 'Dubai';
	src: url('../fonts/dubai-font/DubaiW23-Medium.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/dubai-font/DubaiW23-Medium.woff') format('woff'),
		 url('../fonts/dubai-font/DubaiW23-Medium.ttf') format('truetype'),
		 url('../fonts/dubai-font/DubaiW23-Medium.woff2#Dubai-Medium') format('woff2');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Dubai';
	src: url('../fonts/dubai-font/DubaiW23-Regular.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/dubai-font/DubaiW23-Regular.woff') format('woff'),
		 url('../fonts/dubai-font/DubaiW23-Regular.ttf') format('truetype'),
		 url('../fonts/dubai-font/DubaiW23-Regular.woff2#Dubai-Regular') format('woff2');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Dubai';
	src: url('../fonts/dubai-font/DubaiW23-Light.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/dubai-font/DubaiW23-Light.woff') format('woff'),
		 url('../fonts/dubai-font/DubaiW23-Light.ttf') format('truetype'),
		 url('../fonts/dubai-font/DubaiW23-Light.woff2#Dubai-Light') format('woff2');
	font-weight: 300;
	font-style: normal;
}
