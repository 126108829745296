@font-face {
	font-family: 'NeoSansStd';
	src: url('../fonts/neosansstd/NeoSansStd-Regular.eot#iefix') format('embedded-opentype'),
		 url('../fonts/neosansstd/NeoSansStd-Regular.woff') format('woff'),
		 url('../fonts/neosansstd/NeoSansStd-Regular.ttf') format('truetype'),
		 url('../fonts/neosansstd/NeoSansStd-Regular.woff2#NeoSansStd-Regular') format('woff2');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'NeoSansStd';
	src: url('../fonts/neosansstd/NeoSansStd-Medium.eot#iefix') format('embedded-opentype'),
		 url('../fonts/neosansstd/NeoSansStd-Medium.woff') format('woff'),
		 url('../fonts/neosansstd/NeoSansStd-Medium.ttf') format('truetype'),
		 url('../fonts/neosansstd/NeoSansStd-Medium.woff2#NeoSansStd-Medium') format('woff2');
	font-weight: 500;
	font-style: normal;
}
